import React, { useEffect, useState } from 'react';
import HomePage from './pages/HomePage/HomePage';
import { Routes, BrowserRouter, Route } from 'react-router-dom'
import NoPage from './pages/Nopage/NoPage';
import Exercise from './pages/Exercise/Exercise';
import CreateExercise from './pages/CreateExercise/CreateExercise';
import WorkoutPlans from './pages/WorkoutPlans/WorkoutPlans';
import ViewExercise from './pages/ViewExercise/ViewExercise';
import UpdateExercise from './pages/UpdateExercise/UpdataExercise';

import NewWorkoutPlans from './pages/NewWorkoutPlans/NewWorkoutPlans';
import Clients from './pages/Clients/Clients';
import EditWorkoutPlans from './pages/EditWorkoutPlans/EditWorkoutPlans';
import ViewWorkoutPlans from './pages/ViewWorkoutPlans/ViewWorkoutPlans';
import ContactUs from './pages/ContactUs/ContactUs';
import { configureSite } from './configuration/Config';

const App = () => {
  const [siteConfigured, setSiteConfigured] = useState(false);
  useEffect(() => {
    if (!siteConfigured) {
      configureSite().then(() => setSiteConfigured(true));
    }
  }, []);
  return (
    <main>
      {siteConfigured ? (
      <BrowserRouter>
        <Routes>
          <Route index element={<HomePage/>}/>

          <Route path='*' element={<NoPage/>}/>

          <Route path='/homePage' element={<HomePage/>}/>

          <Route path='/exercise' element={<Exercise/>}/>
          <Route path='/createExercise' element={<CreateExercise/>}/>
          <Route path='/viewexercise' element={<ViewExercise/>}/>
          <Route path='/updateexercise/:id' element={<UpdateExercise/>}/>

          <Route path='/users' element={<Clients/>}/>
          
          <Route path='/workoutplans' element={<WorkoutPlans/>}/>
          <Route path='/newworkoutplans' element={<NewWorkoutPlans/>}/>
          <Route path='/EditWorkoutPlans' element={<EditWorkoutPlans/>}/>
          <Route path='/ViewWorkoutPlans' element={<ViewWorkoutPlans/>}/>

          <Route path='/ContactUs' element={<ContactUs/>}/>

          <Route path='/PrivacyPolicy' element={<HomePage/>}/>
          
          <Route path='/TermsAndConditions' element={<HomePage/>}/>
          
        </Routes>
      </BrowserRouter>
    ) : (<div>Loading...</div>)}
    </main>
  );
}

export default App;
