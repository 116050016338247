import React, { useState } from 'react';
import ThemeSwitcher from '../ThemeSwitch/ThemeSwitcher';
import { FaBars, FaX } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleLinkClick = () => {
        setIsMenuOpen(false);
    };
    return (
        <header className='p-2 pl-0 pr-0 w-full fixed z-20 top-0 start-0'>
            <div className='flex flex-row md:p-3 pt-0 pb-0'>
                <div>
                    <Link to='/' onClick={handleLinkClick} className='p-2 text-3xl font-bold'>
                        FITNESS
                    </Link>
                </div>
                <nav className='hidden md:block mx-auto'>
                    <div className='flex flex-row items-center h-full gap-5 font-semibold md:text-l lg:text-xl lg:gap-8'>
                        <Link to='/workoutplans' className=''>WORKOUT PLANS</Link>
                        <Link to='/exercise' className=''>EXERCISES</Link>
                        <Link to='/users' className=' uppercase'>Users</Link>
                    </div>
                </nav>
                <div className='ml-auto flex items-center gap-5 p-2 md:mr-3'>
                    <ThemeSwitcher />
                    <button
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        className='md:hidden text-2xl'
                        aria-label='Toggle menu'
                    >
                        {isMenuOpen ? <FaX /> : <FaBars />}
                    </button>
                </div>
            </div>
            {isMenuOpen && (
                <nav className='md:hidden bg-[var(--bg-color)] w-full p-2'>
                    <div className='flex flex-col items-start gap-5 font-semibold text-xl'>
                        <Link to='/workoutplans' className=''>WORKOUT PLANS</Link>
                        <Link to='/exercise' className=''>EXERCISES</Link>
                        <Link to='/users' className=' uppercase'>Users</Link>
                    </div>
                </nav>
            )}
        </header>
    );
}
