import ExerciseForm from "../../components/ExerciseForm/ExerciseForm";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

export default function CreateExercise() {
    return (
        <>
            <Header />
            <div className="container mx-auto">
                <section className="mt-[70px] p-2 md:p-0 md:mt-[100px]">
                    <div className="mx-auto">
                        <div className="w-full mx-auto">
                            <h1 className="text-center">Add Exercise</h1>
                        </div>
                        <div className="md:w-8/12 mx-auto p-2">
                            <ExerciseForm/>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
}
