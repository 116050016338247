import React, { useState } from 'react';
import { FaSearch, FaChevronDown, FaCheck, FaTimes } from 'react-icons/fa';

const MultiSelectDropdown: React.FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    const options: string[] = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];

    const toggleDropdown = (): void => {
        setIsOpen(!isOpen);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchTerm(e.target.value);
    };

    const handleOptionClick = (option: string): void => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter((item) => item !== option));
        } else {
            setSelectedOptions([...selectedOptions, option]);
        }
    };

    const handleClearSelectedOptions = (e: React.MouseEvent, option: string): void => {
        e.stopPropagation();
        handleOptionClick(option);
    };

    const filteredOptions = options.filter((option) =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="relative inline-block bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5">
            <div
                className="flex items-center justify-between p-3 bg-gray-50 cursor-pointer"
                onClick={toggleDropdown}
            >
                <div className="flex flex-wrap gap-2">
                    {selectedOptions.map((option, index) => (
                        <div key={index} className="relative flex items-center bg-blue-500 text-white rounded-full px-2 py-1">
                            {option}
                            <FaTimes
                                className="ml-2 text-white bg-blue-500 cursor-pointer"
                                onClick={(e) => handleClearSelectedOptions(e, option)}
                            />
                        </div>
                    ))}
                </div>
                <FaChevronDown />
            </div>
            {isOpen && (
                <div className="absolute left-0 right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
                    <div className="p-2">
                        <div className="flex items-center p-2 border-b border-gray-300">
                            <FaSearch className="mr-2 text-gray-400" />
                            <input
                                type="text"
                                className="w-full p-2 border-none outline-none focus:ring-0"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </div>
                        <ul className="max-h-40 overflow-y-auto">
                            {filteredOptions.map((option, index) => (
                                <li
                                    key={index}
                                    className="flex items-center justify-between p-2 cursor-pointer hover:bg-gray-100"
                                    onClick={() => handleOptionClick(option)}
                                >
                                    {option}
                                    {selectedOptions.includes(option) && (
                                        <FaCheck className="text-blue-500" />
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MultiSelectDropdown;
