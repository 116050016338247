import { Link } from 'react-router-dom';
import ExercisesCard from '../../components/ExercisesCard/ExercisesCard';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { useFetch } from '../../services/useFetch';
import { ExerciseListResponse } from '../../types/ExerciseListResponse';
import { Config } from '../../configuration/Config';

const Exercise = () => {
  const requestOptions = {
    method: 'GET',
    headers: new Headers({
      'content-type': 'application/json',
      'x-fitness-api': Config.headerApiVersion,
    }),
  };

  const [response, isLoading] = useFetch<ExerciseListResponse[]>(
    `${Config.getAllExercisesEndpoint}`,
    requestOptions
  );
  return (
    <>
      <Header />

      <div className='container mx-auto'>
        <section className='mt-[70px] p-2 md:p-0 md:mt-[100px]'>
          <div className='mx-auto flex justify-between items-center'>
            <h1 className='text-3xl font-semibold text-center'>Exercise</h1>
            <div>
              <Link
                to='/createExercise'
                className='px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300'
              >
                Add Exercise
              </Link>
            </div>
          </div>
          {isLoading && <div>loading...</div>}
          {response && (<div className='w-full mx-auto p-5'>
            <div className='gap-5 md:m-5 grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
              {response.map((cardData) => (
                <ExercisesCard
                  key={cardData.id}
                  id={cardData.id}
                  title={cardData.title}
                  description={cardData.description}
                  imageUrl={cardData.imageUrl}
                />
              ))}
            </div>
          </div>)}
          
        </section>
      </div>

      <Footer />
    </>
  );
}

export default Exercise;
