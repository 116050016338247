import React from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

export default function NoPage() {
    return (
        <>
            <Header />

            <div className='flex items-center justify-center min-h-screen'>
                <h1 className=' text-xl md:text-4xl font-bold p-2 m-2'>
                    404 Error : Page was not found
                </h1>
            </div>

            <Footer />
        </>
    );
}