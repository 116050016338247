export const configureSite = async (origin = window.location.origin) => {
    const configPath = origin + '/config.json';
    await fetch(configPath, {
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        Config.apiEndpoint = json.apiEndpoint;
        Config.headerApiVersion = 'fitness-api-v1';
        Config.getAllExercisesEndpoint =
          json.apiEndpoint + 'Exercise';
        Config.getAllWorkoutPlansEndpoint =
          json.apiEndpoint + 'WorkoutPlan';
        Config.getAllUsersEndpoint =
          json.apiEndpoint + 'User';
      });
  };
  
  export class Config {
    static apiEndpoint: string;
    static headerApiVersion: string;
    static getAllExercisesEndpoint: string;
    static getAllWorkoutPlansEndpoint: string;
    static getAllUsersEndpoint: string;
  }
  