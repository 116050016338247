import React, { useState, useEffect } from 'react';
import { FaSun, FaMoon } from "react-icons/fa";

export default function ThemeSwitcher() {
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
      document.body.classList.add('dark-theme');
      document.body.classList.remove('light-theme');
    } else {
      document.documentElement.classList.remove('dark');
      document.body.classList.add('light-theme');
      document.body.classList.remove('dark-theme');
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  return (
    <button onClick={toggleTheme}>
      {theme === 'dark' ? (
        <FaSun className='text-2xl md:text-3xl' />
      ) : (
        <FaMoon className='text-2xl md:text-3xl' />
      )}
    </button>
  );

}