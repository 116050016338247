import React, { useState } from 'react';
import { ExercisesCardProps } from '../../interfaces/exercisesCard';
import { Link } from 'react-router-dom';
import DeleteModal from '../DeleteModel/DeleteModal';

const trimDescription = (description: string, wordLimit: number): string => {
  const words = description.split(' ');
  if (words.length <= wordLimit) {
    return description;
  }
  return words.slice(0, wordLimit).join(' ') + '...';
};

export default function ExercisesCard({ id, title, description, imageUrl }: ExercisesCardProps) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDelete = () => {
    // Implement your delete logic here
    setIsDeleteModalOpen(false);
  };
  return (
    <div className='border border-gray-200 rounded-lg shadow flex flex-col'>

      <a href='/'>
        <img className='rounded-t-lg' src={imageUrl} alt={title} />
      </a>

      <div className='p-5 bg-[var(--exercise-card-color)] flex flex-col justify-between flex-grow'>
        <div className='bg-[var(--exercise-card-color)]'>
          <a href='/'>
            <h5 className='mb-2 text-2xl font-bold tracking-tight bg-[var(--exercise-card-color)]'>{title}</h5>
          </a>
          <p className='mb-3 font-normal bg-[var(--exercise-card-color)]'>{trimDescription(description, 10)}</p>
        </div>
        <div className='flex justify-between bg-[var(--exercise-card-color)]'>

          <Link
            to={`/updateexercise/${id}`}
            className='w-1/4 px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300'
          >
            Edit
          </Link>
          <button
            className='w-1/4 px-3 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300'
            onClick={() => setIsDeleteModalOpen(true)}
          >
            Delete
          </button>
          <DeleteModal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onDelete={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}
