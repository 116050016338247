import { Link } from "react-router-dom";
import ExerciseForm from "../../components/ExerciseForm/ExerciseForm";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";


export default function ViewExercise(){
    return(
        <>
        <Header/>
        <div className=" container mx-auto">

        <section className="mt-[70px] p-2 md:p-0 md:mt-[100px]">
                    <div className="mx-auto">
                    <div className=" mx-auto flex justify-between items-center">
                        <h1 className=" text-3xl font-semibold text-center"> Update Exercise</h1>
                        <div>
                            <Link to="/createExercise" className=" px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">Add Exercise</Link>
                        </div>
                    </div>
                        <div className="md:w-8/12 mx-auto p-2">
                            <ExerciseForm/>
                        </div>
                    </div>
                </section>
            
        </div>
        <Footer/>
        </>
    );
}