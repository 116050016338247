import React from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

export default function HomePage() {
    return (
        <>
            <Header />
            <div className='container mx-auto'>
                <section className='mt-[70px] p-2 md:p-0 md:mt-[100px] min-h-screen'>
                    <div>
                        <h1>
                            Home Page
                        </h1>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
}