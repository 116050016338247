import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { Config } from '../../configuration/Config';
import { useFetch } from '../../services/useFetch';
import { UserResponse } from '../../types/UserResponse';

export default function Clients() {
  const requestOptions = {
    method: 'GET',
    headers: new Headers({
      'content-type': 'application/json',
      'x-fitness-api': Config.headerApiVersion,
    }),
  };

  const [response, isLoading] = useFetch<UserResponse[]>(
    `${Config.getAllUsersEndpoint}`,
    requestOptions
  );
  return (
    <>
      <Header />
      {isLoading && <div>loading...</div>}
      <div className='container mx-auto'>
        <section className='mt-[70px] p-2 md:p-0 md:mt-[100px]'>
          {response && (
            <div>
              {response.map((cardData) => (
                <>
                  <div className='gap-5 md:m-5 grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
                    {/* card */}
                    <div className='border border-gray-200 rounded-lg shadow flex flex-col '>
                      <div className='p-5 bg-[var(--exercise-card-color)] flex flex-col justify-between flex-grow'>
                        <div className='bg-[var(--exercise-card-color)]'>
                          <p className='my-3 font-normal bg-[var(--exercise-card-color)]'>
                            {cardData.firstName} {cardData.lastName}
                          </p>
                          <p className='my-3 font-normal bg-[var(--exercise-card-color)]'>
                            {cardData.primaryContact}
                          </p>
                          <p className='my-3 font-normal bg-[var(--exercise-card-color)]'>
                            {cardData.secondaryContact}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* card */}
                  </div>
                </>
              ))}
            </div>
          )}
        </section>
      </div>
      <Footer />
    </>
  );
}
