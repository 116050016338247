import React, { useState } from 'react';
import { FaCloudUploadAlt } from "react-icons/fa";

export default function ExerciseForm() {
    const [fileName, setFileName] = useState('');

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setFileName(file.name);
        } else {
            setFileName('Click to upload Exercise Image');
        }
    };

    return (
        <>
            <form>
                <div className="mb-6">
                    <label htmlFor="exercise-name" className="block mb-2 text-sm font-medium text-[var(--text-color)]">Exercise Name</label>
                    <input name="title" type="text" id="exercise-name" className="bg-[var(--textarea-bg-color)] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                </div>

                <div className="mb-6">
                    <label htmlFor="exercise-description" className="block mb-2 text-sm font-medium text-[var(--text-color)]">Exercise Description</label>
                    <textarea name="description" id="exercise-description" rows={8} className="block p-2.5 w-full text-sm text-gray-900 bg-[var(--textarea-bg-color)] rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Write thoughts here..." required></textarea>
                </div>

                <div className="flex items-center justify-center w-full mb-6">
                    <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-44 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-[var(--textarea-bg-color)]">
                        <div className="flex flex-col items-center justify-center pt-5 pb-6 bg-[var(--textarea-bg-color)]">
                            <FaCloudUploadAlt className="text-4xl bg-[var(--textarea-bg-color)]" />
                            {fileName && <p className="my-3">{fileName}</p>}
                            {!fileName && (
                                <>
                                    <p className="mb-2 text-sm text-center bg-[var(--textarea-bg-color)]"><span className="font-semibold bg-[var(--textarea-bg-color)]">Click to upload Exercise Image</span> or drag and drop</p>
                                    <p className="text-xs bg-[var(--textarea-bg-color)]">PNG, JPG</p>
                                </>
                            )}
                        </div>
                        <input name="imageUrl" id="dropzone-file" type="file" className="hidden" onChange={handleFileChange} />
                    </label>
                </div>
                <div className="flex items-center justify-center md:justify-start">
                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">Add Exercise</button>
                </div>
            </form>
        </>
    );
}
