import React from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <>
            <footer className=" rounded-lg shadow w-full">
                <div className="w-full mx-auto ">
                    <div className="sm:flex sm:items-center sm:justify-between ">
                        <Link to="/" className=" p-4 flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                            <span className="self-center text-2xl font-semibold whitespace-nowrap uppercase">fitness</span>
                        </Link>
                        <ul className=" p-2 flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 ">
                            <li>
                                <div className="flex flex-row gap-5 text-xl me-2 md:me-6">
                                <Link to=""><FaFacebookF /></Link>
                                <Link to=""><FaInstagram /></Link>
                                <Link to=""><FaXTwitter /></Link>
                                </div>
                            </li>
                            <li>
                                <Link to="/ContactUs" className="hover:underline me-2 md:me-6">Contact US</Link>
                            </li>
                            <li>
                                <Link to="" className="hover:underline me-2 md:me-6">Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to="" className="hover:underline me-2 md:me-6">Terms & Conditions</Link>
                            </li>
                        </ul>
                    </div>
                    <hr className="my-1 border-gray-200 mx-auto w-full" />
                    <span className="px-4 pb-3 block text-sm text-gray-500 text-center ">© 2023 <Link to="" className="hover:underline">Qloudy</Link>. All Rights Reserved.</span>
                </div>
            </footer>
        </>
    );
}