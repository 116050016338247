import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { Link } from 'react-router-dom';
import { Config } from '../../configuration/Config';
import { useFetch } from '../../services/useFetch';
import { WorkoutPlanResponse } from '../../types/WorkoutPlanResponse';

export default function WorkoutPlans() {
  const requestOptions = {
    method: 'GET',
    headers: new Headers({
      'content-type': 'application/json',
      'x-fitness-api': Config.headerApiVersion,
    }),
  };

  const [response, isLoading] = useFetch<WorkoutPlanResponse[]>(
    `${Config.getAllWorkoutPlansEndpoint}`,
    requestOptions
  );
  return (
    <>
      <Header />
      <div className='container mx-auto'>
        <section className='mt-[70px] p-2 md:p-0 md:mt-[100px]'>
          <div className='mx-auto flex justify-between items-center'>
            <h1 className='text-3xl font-semibold text-center'>
              Workout Plans
            </h1>
            <div>
              <Link
                to='/newworkoutplans'
                className='px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300'
              >
                New Workout Plans
              </Link>
            </div>
          </div>
          {isLoading && <div>loading...</div>}
          {response && (
            <div>
              {response.map((cardData) => (
                <>
                  <div className='gap-5 md:m-5 grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
                    {/* card */}
                    <div className='border border-gray-200 rounded-lg shadow flex flex-col '>
                      <div className='p-5 bg-[var(--exercise-card-color)] flex flex-col justify-between flex-grow'>
                        <div className='bg-[var(--exercise-card-color)]'>
                          <a href='/'>
                            <h5 className='my-2 text-2xl font-bold tracking-tight bg-[var(--exercise-card-color)]'>
                              {cardData.title}
                            </h5>
                          </a>
                          <p className='my-3 font-normal bg-[var(--exercise-card-color)]'>
                            {cardData.description}
                          </p>
                        </div>
                        <div className='flex justify-between bg-[var(--exercise-card-color)] my-5'>
                          <Link
                            to='/ViewWorkoutPlans'
                            className='w-1/4 px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300'
                          >
                            View
                          </Link>
                          <Link
                            to='/EditWorkoutPlans'
                            className='w-1/4 px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300'
                          >
                            Edit
                          </Link>
                          <button className='w-1/4 px-3 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300'>
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* card */}
                  </div>
                </>
              ))}
            </div>
          )}
        </section>
      </div>
      <Footer />
    </>
  );
}
