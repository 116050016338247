import { useState, useEffect, useCallback } from 'react';

export const useFetch = <T>(
  url: string,
  init: RequestInit
): [T, boolean, Headers] => {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(true);
  const [headers, setHeaders] = useState(new Headers());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setError] = useState();

  // Hack into the component lifecycle and throw an error so error boundary picks it up
  const throwError = useCallback(
    (e: Error) => {
      setError(() => {
        throw e;
      });
    },
    [setError]
  );

  useEffect(() => {
    async function FetchURL() {
        const response = await fetch(url, init);
        if (response.ok) {
          const data = await response.json();
          setData(data as T);
          setHeaders(response.headers);
          setLoading(false);
          return data;
        } else if (response.status === 400 || response.status === 404) {
          setLoading(false);
          return null;
        }
        return Promise.reject(response);
    }
    FetchURL().catch((e) => throwError(e));
  }, [url]);

  return [data as T, loading, headers];
};
