import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import MultiSelectDropdown from "../../components/MultiSelectDropdown/MultiSelectDropdown";
import Footer from "../../components/Footer/Footer";

export default function EditWorkoutPlans() {
    return (
        <>
            <Header />
            <div className="container mx-auto">
                <section className="mt-[70px] p-2 md:p-0 md:mt-[100px] min-h-screen">
                    <div className="mx-auto flex justify-between items-center ">
                        <h1 className="text-lg md:text-3xl font-semibold text-center">New Workout Plans</h1>
                        <div>
                            <Link to="/workoutplans" className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
                                Back
                            </Link>
                        </div>
                    </div>
                    <div className="mx-auto mt-10">
                        <div className="mx-auto w-full md:p-10 ">
                            <div className="p-2 md:p-5">
                                <label htmlFor="helper-text" className="block mb-2 text-sm font-medium capitalize">Add Name to the List</label>
                                <input type="email" id="helper-text" placeholder="eg..Morning Workout" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                            </div>
                            <div className="p-2 md:p-5">
                                <label htmlFor="helper-text" className="block mb-2 text-sm font-medium capitalize">Add Description to the plans</label>
                                <textarea id="message" rows={5} placeholder="eg..should Start on morning 6am to 8am" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" ></textarea>
                            </div>

                            <div className="p-2 md:p-5 pt-0">
                                <label className="block mb-2 text-sm font-medium capitalize">Select The Exercises</label>
                                <MultiSelectDropdown />
                            </div>

                            <div className="p-5 flex justify-center md:justify-start">
                                <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2">Update Workout List</button>
                                <button type="button" className="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2">Delete Workout List</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />

        </>
    )
}