import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

export default function ContactUs() {
    return (
        <>
            <Header />
            <section className=" container mx-auto mt-[70px] p-2 md:p-0 md:mt-[100px]">
                <section className="bg-white ">
                    <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md ">
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 ">Contact Us</h2>
                        <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 sm:text-xl">Got a issue? Want to send feedback about a beta feature? Need details about our Business plan? Let us know.</p>
                        <form action="#" className="">
                            <div className="py-5">
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Your email</label>
                                <input type="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 " placeholder="name@flowbite.com" required />
                            </div>
                            <div className="py-5">
                                <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-900 ">Subject</label>
                                <input type="text" id="subject" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm " placeholder="Let us know how we can help you" required />
                            </div>
                            <div className="sm:col-span-2 py-5">
                                <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 ">Your message</label>
                                <textarea id="message" rows={6} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 " placeholder="Leave a comment..."></textarea>
                            </div>
                            <div className=" py-5 flex md:justify-start justify-center">
                                <button type="submit" className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-blue-700 sm:w-fit hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-slate-300">Send message</button>
                            </div>
                        </form>
                    </div>
                </section>


            </section>
            <Footer />

        </>
    )
}